<template>
  <div class="user">
    <!-- 新增岗位 -->
    <el-dialog
      v-dialogDrag
      title="新增岗位"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="userAddSubmit(form)" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑权限 -->
    <el-dialog
      v-dialogDrag
      title="编辑权限"
      :visible.sync="dialogFormVisible4"
      @close="userClose(form)"
    >
      <el-tree
        :data="treelist"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="tree"
        highlight-current
        :props="defaultProps"
      >
        <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
        </span> -->
      </el-tree>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="editJust()" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改用户 -->
    <el-dialog
      v-dialogDrag
      title="修改岗位名称"
      :visible.sync="dialogFormVisible2"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="edit" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog
      v-dialogDrag
      title="删除菜单"
      :visible.sync="dialogVisible3"
      width="30%"
    >
      <span>{{ userdelinfo.name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="getDel" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="inputSearch" size="small">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search(inputSearch)"
              size="small"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="dialogFormVisible = true"
            size="small"
            >添加岗位</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-tag
              style="margin-right: 10px; margin-top: 5px"
              v-for="(item, index) in props.row.fm_name"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="岗位名称"
          width="120"
        ></el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <el-switch
              @change="changeState(scope.row.state, scope.row.id)"
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-search"
              @click="handleJust(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import { getAuthority, getEdit, getAdd, getList, getOption } from '@/api/role'

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Breadcrumb,
  },
  data() {
    //这里存放数据
    return {
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      getNav: [],
      //当前页码
      getInfo: {
        //搜索框
        path: null,
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //单选列表
      radiolist: [],
      //上级列表
      options: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      //控制弹窗--修改
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      //控制弹窗----权限
      dialogFormVisible4: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      treelist: [],
      defaultProps: {
        children: 'son',
        label: 'name',
      },
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //打开权限
    handleJust(info) {
      this.dialogFormVisible4 = true
      this.form = info
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.form.jurisdiction)
      })
      console.log(info)
    },
    //编辑权限
    async editJust() {
      console.log(this.$refs.tree.getCheckedKeys())
      let infoJust = {
        id: this.form.id,
        fm_id: this.$refs.tree.getCheckedKeys(),
      }
      console.log(infoJust)
      let info = await getAuthority(infoJust)
      if (info.data.code == 0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.catalogue()
        this.dialogFormVisible4 = false
      } else {
        this.$message({
          showClose: true,
          message: '修改失败',
          type: 'error',
        })
        return
      }
      this.catalogue()
    },
    //修改状态
    changeState(state, id) {
      this.getDel({ id: id })
    },
    //父级增加
    pAdd(info) {
      this.dialogFormVisible = true
    },
    //详情
    detail(value, a) {
      console.log(value)
      //   console.log(a);
      let number = value
      console.log(number)
      this.goEditgoods(number)
    },
    //详情事件
    goEditgoods(info) {
      this.$router.push({
        path: '/catalogInfo',
        query: { id: info.id, name: info.name },
      })
    },
    dx() {},
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //编辑
    handleEdit(row) {
      this.dialogFormVisible2 = true
      console.log(this.form)
      this.form = row
    },
    //编辑
    async edit() {
      let info = await getEdit(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.dialogFormVisible2 = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: '修改失败',
          type: 'error',
        })
        return
      }
      this.catalogue()
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    async getDel(info2) {
      let info = await getEdit(info2)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      this.dialogVisible3 = false
    },
    //搜索事件
    search(e) {
      console.log('搜索信息： ' + e)
    },
    //添加事件--提交事件
    async userAddSubmit(e) {
      console.log(e)
      let info = await getAdd(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: '添加失败',
          type: 'error',
        })
        return
      }
      this.dialogFormVisible = false
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.getNav = this.$getNavn(this.$route.path)
      let info = await getList(this.getInfo)
      let info2 = await getOption()
      this.treelist = info2.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableData = info.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
</style>
